import React, { useRef, useState } from "react"
import styled from "styled-components"

import ThemeDefault from "../theme/ThemeDefault";
// import {device} from "../theme/MediaQueries"
import Spinner from 'react-spinkit';

import LoadedButton from "../elements/LoadedButton";

import ParseHTML from "../helpers/ParseHTML";

import Tick from "../../assets/images/icons/tick.svg";
import { randomID } from "../helpers/helpers"

const PriceCard = styled.div.attrs((props) => ({
  className: props.cssClass,
}))`

.small-text {
  font-size: 11px;
  margin-left: 2px;
  letter-spacing: 0;
}

.inner {
    background-color: ${(props) => props.bgColour};
    padding: 16px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    overflow: hidden;
		height: 100%;
		justify-content: space-between;
		display: flex;
		flex-direction: column;
  }

  .tierTitle,
  .tierPrice,
  .tierText,
  .tierButton {
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 16px;
  }

  .tierTitle {
    text-align: center;
    font-family:  ${ThemeDefault.fontSecondary};
    font-size:  ${ThemeDefault.font_4};
    line-height: 1.4;
    letter-spacing: -1px;
    font-weight: 400;
  }

  .feature {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1.2;
    margin: 8px 0;
    padding: 8px;
    position: relative;


    &:after {
      content: '';
      position: absolute;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      width: 80%;
      bottom: -4px;
      left: 50%; 
      transform: translateX(-50%);
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  .tick {
    color: ${ThemeDefault.blue};
    width: 16px; 
    margin-right: 8px;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .tierPrice {
    .price {
      font-size: ${ThemeDefault.font_6};
      letter-spacing: 1px;
      line-height: 1;
    }
    .currency {
      padding-top: 4px;
      margin-left: 8px;
    }
  }

  .accordion__title {
    font-weight: 400;
    text-align: center;
    margin-top: 24px;
    font-size: ${ThemeDefault.font_0};
    color: ${ThemeDefault.blue};
    cursor: pointer;
  }

  .accordion__content {
    overflow: hidden;
    transition: max-height 0.6s ease;

  }


`


const ExpandComponent = (props) => {

  const { index } = props;

  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setTitle, setTitleState] = useState("See Features");

  const handleKeyDown = (ev) => {
    // check keys if you want
    if (ev.keyCode === 13) {
      toggleExpand()
    }
  }

  const toggleExpand = () => {
    setActiveState(setActive === "" ? "active" : "");

    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    )

    setTitleState(
      setActive === "active" ? "See Features" : "Hide Features"
    )
  }

  const content = useRef(null)

  return (
    <div className="accordion__section" >
      <div role="button" tabIndex={index} className={`accordion ${setActive}`} onClick={toggleExpand} onKeyDown={handleKeyDown} >
        <p className="accordion__title">{setTitle}</p>
      </div>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className={`accordion__content ${setActive}`}
      >
        <div className="accordion__text"> {props.children} </div>
      </div>
    </div>
  );
}

const PriceTierCard = (data) => {
  let { tierFeatures, tierLink, tierPriceMonth, tierPriceAnnual, tierButtonText, tierTitle, tierText, tierColour } = data.data;
  const priceInformation = data.priceInformation;
  const priceIsLoading = data.priceIsLoading;
  const index = data.index;
  const first3TierFeatures = tierFeatures.slice(0, 3);
  const otherTierFeatures = tierFeatures.slice(3, tierFeatures.length);

  const renderTierPrice = () => {
    if (typeof window == 'undefined' || priceIsLoading) {
      
      return (<div className="tierPrice">
          <div className="price">
            <span className="loading-animation"><Spinner name="ball-beat" /></span>
            <span className="small-text"></span>
          </div>
          <div className="currency"></div>
      </div>);
    }

    // Set default values for previously hard coded values as a fall back
    let tierCurrencySymbol = '$';
    let tierTaxName = 'Tax';
    let tierCurrencyCode = 'USD';

    if (priceInformation) {
      const priceInfoMonth = priceInformation.find((info) => info.plan == `tier${index + 1}` && info.frequency == "monthly");
      const priceInfoYear = priceInformation.find((info) => info.plan == `tier${index + 1}` && info.frequency == "yearly");

      if (priceInfoMonth && priceInfoYear) {
        tierPriceMonth = priceInfoMonth.pricePerMonth;
        tierPriceAnnual = priceInfoYear.pricePerMonth;
        tierCurrencySymbol = priceInfoMonth.currencySymbol;
        tierTaxName = priceInfoMonth.taxName;
        tierCurrencyCode = priceInfoMonth.currencyCode;
      }
    }
    
    const price = (data.term === 'Annually') ? tierPriceAnnual : tierPriceMonth

    return (
      <div className="tierPrice">
						<div className="price">
              { !isNaN(price) && <span>{tierCurrencySymbol}</span>}{price}
              { !isNaN(price) && <span className="small-text">ex {tierTaxName}</span>}
            </div>
            { !isNaN(price) && <div className="currency"><small>{tierCurrencyCode}/MONTH</small></div> }
					</div>
    )
  }

  return (
    <PriceCard cssClass="PriceCard" bgColour={tierColour}>
      <div className="inner">
				<div className="top">
					<div className="tierTitle">
						{tierTitle}
					</div>
					<div className="tierText">
						{ParseHTML(tierText)}
					</div>
					{renderTierPrice()}
					<div className="tierButton">
						<LoadedButton to={tierLink ? tierLink : `/`} className="blue">{tierButtonText}</LoadedButton>
					</div>        
					{ (data.index === 0) ? <div className="c-center"><small>No Credit Card Required<br />30 Day Free Trial</small></div> : <div className="c-center"><small> { `all the benefits of ${data.previousLevel}, and` } </small></div>}
					<div className="features">
						{first3TierFeatures.map((feature, index)=>(
							<div className="feature" key={`top-feature-${index}-${randomID()}`}>
								<div className="tick"><Tick /></div>
								<div className="text">{feature.tierFeature}</div>
							</div>
						))}
					</div>
				</div>
				<div className="bottom">				
					<div className="buttonWrap">
						<ExpandComponent>
							{otherTierFeatures.map((feature, index)=>(
								<div className="feature" key={`bottom-feature-${index}-${randomID()}`}>
									<div className="tick"><Tick /></div>
									<div className="text">{feature.tierFeature}</div>
								</div>
							))}
						</ExpandComponent>
					</div>
				</div>
      </div>
    </PriceCard>
  );
}

export default PriceTierCard