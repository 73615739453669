import React from "react"
import Base from "./Base"
import PricingPage from "../Pricing/PricingPage";

const PricingPageTemplate = ({ pageContext }) =>  {
    
  return (
    <Base context={pageContext}>
    {/* {pageContext.isFrontPage ? <ComponentParser key={pageContext} content={pageContext.blocks} /> : <section className="section-bottom"><div className="inner-div"><div className="page-content" dangerouslySetInnerHTML={{ __html: pageContext.content }} /></div></section>} */}
      <PricingPage data={pageContext} />
    </Base>
  )
}

export default PricingPageTemplate