import React, { useRef, useState } from "react"

import styled from "styled-components"

import ThemeDefault from "../theme/ThemeDefault";
// import {device} from "../theme/MediaQueries"

import { usePricingData } from "../hooks/usePricingData";

// import Tick from "../../assets/images/icons/tick.svg";
import { randomID } from "../helpers/helpers"

const SectionFAQComponent = styled.section.attrs((props) => ({
  className: props.cssClass,
}))`
  
  background-color: ${(props) => props.bgColour};

  .inner-div {
    padding-top: 0;
    padding-bottom: 0;
    align-items: center;
  }

  h2 {
    line-height: 1.3;
    text-align: left;
    font-family: ${ThemeDefault.fontSecondary};
    font-weight: 500;

    p {
      margin-bottom: 20px;
    }
  }


  .tier-col {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

`

const SectionComponent = styled.section.attrs((props) => ({
  className: props.cssClass,
}))`
  
  background-color: ${(props) => props.bgColour};

  .inner-div {
    padding-top: 16px;
    padding-bottom: 64px;

    & > .bs {
      background: #fff;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);
    }
  }

  h1 {
    font-size: ${ThemeDefault.font_6};
    line-height: 1.4;
    letter-spacing: -1px;
    margin: 32px 0;
    text-align: left;
    font-weight: 500;
    
    p {
      margin-bottom: 20px;
    }
  }


  .tier-col {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    
    & svg {
      width: 16px;
      height: 16px;
      color: ${ThemeDefault.blue}
    }
  }

  .accordion {
    width: 100%;
    background: ${ThemeDefault.white};
    color: ${ThemeDefault.black};
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    transition: 0.4s ease;
    padding: 24px 8px;
    border-bottom: 1px solid ${ThemeDefault.greyLight};
    box-shadow: none;
   

    p {
      margin: 0;
      padding: 0;
    }

    :hover {
      border-bottom: 1px solid ${ThemeDefault.greyDark};
      .icon {
        transform: rotate(-45deg);
      }
    }

    &.active {
      border-bottom: 1px solid ${ThemeDefault.greyDark};
      :hover .icon {
        transform: rotate(-360deg);
      }
    }
  }

  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .accordion:hover,
  .active {
    box-shadow: none;
  }

  /* Style the accordion content title */
  .accordion__title {
    font-weight: 400;
    text-transform: none;
    font-size: ${ThemeDefault.font_1};
    line-height: 1;
  }

  /* Style the accordion chevron icon */
  .accordion__icon {
    margin: 0 16px;
    transition: transform 0.4s ease;
    transform: rotate(45deg);
  }

  /* Style to rotate icon when state is active */
  .rotate {
    transform: rotate(360deg);
  }

  /* Style the accordion content panel. Note: hidden by default */
  .accordion__content {
    overflow: hidden;
    transition: max-height 0.6s ease;

    &.active{
      border-bottom: 1px solid ${ThemeDefault.greyDark};
    }
  }

  /* Style the accordion content text */
  .accordion__text {
    /* padding: 16px; */

    & .answer-row {

      & div {
        padding: 16px;
      }

      & .title-col {
        line-height: 1.1;
      }

      &:nth-of-type(odd) {
        /* background: ${ThemeDefault.offWhite} */
      }
    }
  }

  .icon {
    padding: 2px;
    flex-shrink: 0;
  }


`

// const Chevron = (props) => {

//   return (
//     <svg
//       className={props.className}
//       height={props.height}
//       width={props.width}
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox="0 0 10.95 10.95">
//       <polygon points="5.48 6.43 0.96 10.95 0 9.99 4.5 5.47 0 0.96 0.96 0 5.48 4.52 9.99 0 10.95 0.96 6.43 5.47 10.95 9.99 9.99 10.95 5.48 6.43"/>
//     </svg>
//   );
//  }


const Chevron = (props) => {

  return (
    <svg
      className={props.className}
      height={props.height}
      width={props.width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30.12 30.12">
      <path d="M15.75.94A15.06,15.06,0,1,0,30.81,16,15.06,15.06,0,0,0,15.75.94Zm0,29.12A14.06,14.06,0,1,1,29.81,16,14.05,14.05,0,0,1,15.75,30.06Zm8.13-22.9L15.75,15.3,7.61,7.16l-.71.71L15,16,6.9,24.14l.71.71,8.14-8.14,8.13,8.14.71-.71L16.45,16l8.14-8.13Z" transform="translate(-0.69 -0.94)"/>
    </svg>
  );
 }
 

const AccordionComponent = (props) => {

  const { title, index } = props;

  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");

  const handleKeyDown = (ev) => {
    // check keys if you want
    if (ev.keyCode === 13) {
      toggleAccordion()
    }
  }

  const toggleAccordion = () => {
    setActiveState(setActive === "" ? "active" : "");

    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );

    setRotateState(
      setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
    );
  }

  const content = useRef(null);

  return (
    <div>
      <div role="button" tabIndex={index} className={`accordion ${setActive}`} onClick={toggleAccordion} onKeyDown={handleKeyDown} >
        <Chevron className={`icon ${setRotate}`} width={32} stroke={ThemeDefault.black} />
        <p className="accordion__title">{title}</p>
      </div>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className={`accordion__content ${setActive}`}
      >
        <div className="accordion__text"> { props.children } </div>
      </div>
    </div>
  );
}


const FAQSection = ( data ) => {

  const pricingData = usePricingData();

  return (
    <>
      <SectionFAQComponent cssClass="m-top" >
        <div className="inner-div wrapper hero-inner c-columns-6">
          <div className="title-col c-col-span-3 ">
            <h2>Frequently Asked Quenstions</h2>
          </div>
        </div>
      </SectionFAQComponent>
      <SectionComponent cssClass="">
        <div className="inner-div wrapper accordion__section" >
          <div className="bs">
            {pricingData.page.Pricing.faqs.map((faq, index)=>(
              <AccordionComponent title={faq.question} key={`faq-${index}-${randomID()}`}>
                <div className="answer-row">
                  <div className="title-col">{ faq.answer }</div>
                </div>
              </AccordionComponent>
            ))} 
          </div>
        </div>  
      </SectionComponent>
    </>
  );
}

export default FAQSection


