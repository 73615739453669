import React, { useState } from "react"
import styled from "styled-components"
import ThemeDefault from "../theme/ThemeDefault"

export const Toggle = ({ labels, toggled, onClick }) => {
    const [isToggled, toggle] = useState(toggled)

    const callback = () => {
        toggle(!isToggled)
        onClick(!isToggled)
    }

    return (
        <ToggleControl>
            <div className="left"  style={isToggled ? {fontWeight: '400'} : {fontWeight: '400'}}>{labels[0]}</div>
            <input type="checkbox" defaultChecked={isToggled} onClick={callback} />
            <span />
            <div className="right" style={isToggled ? {fontWeight: '400'} : {fontWeight: '400'}}>{labels[1]}</div>
        </ToggleControl>
    )
}

const ToggleControl = styled.label`

	position: relative;
	display: inline-block;
	width: 60px;
	height: 30px;

	& input {
		opacity: 0;
		width: 0;
		height: 0;

		&:checked + span {
			/* background-color: ${ThemeDefault.blue}; */

			&:before {
				transform: translateX(calc(100% + 8px));
			}
		}
	}

	& span {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: ${ThemeDefault.blueLight};
			transition: 0.3s;
			border-radius: 30px;
			border: 2px solid ${ThemeDefault.blue};

			&:before {
				position: absolute;
				content: "";
				height: 22px;
				width: 22px;
				left: 2px;
				bottom: 2px;
				background-color: ${ThemeDefault.blue};
				border-radius: 50%;
				transition: 0.3s;
			}
	}

	& div {
    position: absolute;
    left: 100%;
    width: max-content;
    line-height: 30px;
    margin-left: 10px;
    cursor: pointer;
		top: 0;
		
		&.left {
			left: auto;
			right: calc(100% + 10px);
		}
	}

`