import React, { useEffect, useRef, useState } from "react"

import styled from "styled-components"

import { usePricingData } from "../hooks/usePricingData";

import ThemeDefault from "../theme/ThemeDefault";

import PriceTierCard from "./PricingCard"

import Sit from "../../assets/images/price-sit.svg"
import Point from "../../assets/images/price-stand-point.svg"

import HubspotEmailCapture from "../blocks/HubspotEmailCapture";
import CompareFeatures from "./CompareFeatures";
import FAQSection from "./FAQSection";
import { Toggle } from "../Toggle/Toggle";
import device from "../theme/MediaQueries";

const PricingSectionComponent = styled.section.attrs((props) => ({
  className: props.cssClass,
}))`

  background-color: ${ThemeDefault.offWhite};

	& .priceToggle {
		padding: 0.8rem 0 3.2rem;
		text-align: center;
		z-index: 2;

		& h2 {
			width: 100%;
			text-align: center;
			margin-bottom: 1.6rem;
		}
	}
	
	& .tierWrapper {
		position: relative;
		margin-top: 4.8rem;
	}

`

const SectionComponent = styled.section.attrs((props) => ({
  className: props.cssClass,
}))`
  
  background-color: ${(props) => props.bgColour};

  .inner-dev {
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;
  }
  h1 {
    font-size: ${ThemeDefault.font_6};
    line-height: 1.4;
    letter-spacing: -1px;
    margin: 32px 0;
    text-align: left;
    font-weight: 500;
    width: 100%;
    text-align: center;
    
    p {
      margin-bottom: 20px;
    }
  }



`

const SitWrapper = styled.div`
	position: absolute;
	width: 200px;
	right: 0;
	top: 0;
	transform: translate(70px, -150px);

	@media ${device.MXmd} {
		display: none;
	}	
`
const PointWrapper = styled.div`
	position: absolute;
	width: 170px;
	left: 0;
	bottom: 0;
	transform: translate(-150px,0px);

	@media ${device.MXmd} {
		display: none;
	}	
`

const PricingPage = ( data ) => {
  const pricingData = usePricingData();
  	
  
	const terms = ['Annually', 'Monthly']
	const [toggled, setToggled] = useState(false) 
	const [term, setTerm] = useState(terms[0])

	const priceTierWrap = useRef(null)

  let hubData = {
    hubspotCode: pricingData.page.Pricing.hubspotCode,
    text: pricingData.page.Pricing.hubspotTitle
  };

	useEffect(()=>{
		setTerm(() => toggled ? terms[1] : terms[0] )
	}, [toggled])

	useEffect(()=>{

		if (priceTierWrap.current) {

			const el = priceTierWrap.current
			const text = el.querySelectorAll('.tierText')
			const price = el.querySelectorAll('.tierPrice')
			
			let textHeight = 0;
			let priceHeight = 0;

			text.forEach((t)=>{
				const h = t.offsetHeight;
				textHeight = (h > textHeight) ? h : textHeight;
			})
			price.forEach((t)=>{
				const h = t.offsetHeight;
				priceHeight = (h > priceHeight) ? h : priceHeight;
			})

			text.forEach((t)=>{
				t.style.height = `${textHeight}px`
			})
			price.forEach((t)=>{
				t.style.height = `${priceHeight}px`
			})

		}

	}, [priceTierWrap])

	const [priceInformation, setPriceInformation] = useState([]);
  	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(`${process.env.GATSBY_MERCURL}/price-information`);
				const data = await response.json();
				setPriceInformation(data);
				setIsLoading(false);
			} catch (error) {
				console.error("Error fetching price information:", error);
				setIsLoading(false);
			}
		};

		fetchData();
	}, []);

  return (
    <>
      <SectionComponent cssClass="m-top" bgColour={pricingData.page.Pricing.sectionBgColour} >
        <div className="inner-div wrapper">
          <div className="page-content text--left" >
            <h1 className="text--left" style={{textAlign: 'left', marginBottom: '0.8rem', color: "#fff"}}> Loaded Pricing </h1>
            <h2 className="text--left" style={{textAlign: 'left', color: "#fff"}}>{ pricingData.page.Pricing.title }</h2>
          </div>
        </div>
      </SectionComponent>
      <PricingSectionComponent>
        <div className="inner-div wrapper">
					<div className="priceToggle">
						<h2>Choose the plan that suits your business best</h2>
						<div style={{fontWeight: '400', marginBottom: '0.2rem' }}>Pay</div>
						<Toggle labels={terms} toggled={toggled} onClick={()=>setToggled(!toggled)} />
					</div>
					<div className="tierWrapper">
						<SitWrapper className="man-1">
            	<Sit />
						</SitWrapper>
          	<div className="c-columns-4 c-columns-l-2 c-columns-m-1 c-columns-gap-s" ref={priceTierWrap}>
							{pricingData.page.Pricing.priceTiers.map((tier, index)=> (
									<PriceTierCard 
										data={tier}
										term={term}
										key={`tiercard-${index}`}
										previousLevel={(index >= 1) && pricingData.page.Pricing.priceTiers[index - 1].tierTitle }
										index={index} 
										priceIsLoading={isLoading}
										priceInformation={priceInformation}
										/>
								))
							}
						</div>
						<PointWrapper className="man-2">
							<Point />
						</PointWrapper>
					</div>
        </div>
      </PricingSectionComponent>
      <HubspotEmailCapture data={hubData} />
      <CompareFeatures />
      <FAQSection />
    </>
  );
}

export default PricingPage


