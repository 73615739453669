import React, { useState, useEffect } from "react"
import axios from "axios"

import styled from "styled-components"
import LoadedFormButton from "../elements/LoadedFormButton"

import ParseHTML from "../helpers/ParseHTML"

import Cookies from 'js-cookie';
import { useStateMachine } from 'little-state-machine'
import { updateUserDetails } from '../helpers/updateUserDetails'

const SectionComponent = styled.section.attrs(props => ({
  className: props.cssClass,
}))`
  
  .col-1 {

    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: flex-start;

    & .title {
      font-weight: 700;
    }
  }

  .hubsport-form-wrapper {
    display: flex;
    flex-direction: row;

    & input {
      padding: 8px;
      border-radius: 0;
      border: 1px solid gray;
      max-height: 38px;
      margin-right: 8px;
      width: 250px;
    }
  }

` 

const HubspotEmailCapture = ( data ) => {
  
  const url = typeof window !== 'undefined' ? window.location.href : '';
  
  const [email, setEmail] = useState("")
  const [formMessage, setformMessage] = useState("Form sent. Thank you!")
  
  const portalId = "20809464"
  const formId = data.data.hubspotCode
  //console.log(formId);

  const [isLoading, setIsLoading] = useState(false)
  const [isSent, setIsSent] = useState(false)

  const { actions, state } = useStateMachine({ updateUserDetails });

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    const config = { // important!
      headers: {
        'Content-Type': 'application/json',
      }
    }

    const response = await axios.post(
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
        {
          portalId,
          formId,
          context: {
            ipAddress: state.userDetails.ipAddress,
            hutk: Cookies.get('hubspotutk'),
            pageUri: url,
          },
          fields: [
            {
              name: "email",
              value: email,
            },
          ],
          config
        }
      )
      .then(function(response) {
        //console.log(response)
        const message = response.data.inlineMessage
        setformMessage(message)
        setIsLoading(false)
        setIsSent(true)
      })
      
      //console.log(response);

  }



  // useEffect(() => {
  //   initializeHubspotForm()
  // }, [])

  // const initializeHubspotForm = () => {
  //   if ('hbspt' in window) { // Just to make sure the script is loaded (you can also do it by attaching an `onload` event to the script but I did it in this way
  //     window.hbspt.forms.create({
  //       portalId: "20809464",
  //       formId: data.data.hubspotCode,
  //       target: "#hubsport-form-wrapper",
  //     });
  //   } else {
  //     setTimeout(() => initializeHubspotForm(), 500)
  //   }
  // }

  return (
    <SectionComponent key={`slider-${data.index}`} >
      <div className="inner-div wrapper slider-post">
        <div className="c-columns-1 c-columns-gap-xs c-columns-l-1">
          <div className="col-1 c-relative">
            <div className="c-full-width title c-center c-margin-bottom-xs">{ data.data.text && ParseHTML(data.data.text) }</div>
            <div className="hubspot-form c-full-width c-center">
              <div id="hubsport-form-wrapper" className="hubsport-form-wrapper">
                {isSent && (
                  <div style={{ fontWeight: "bold", color: "#628cf6" }}>
                    { ParseHTML(formMessage) }
                  </div>
                )}
                {!isSent && (
                  <>
                    <form onSubmit={handleSubmit} id={formId}>
                      <input 
                        type="email"
                        name="email"
                        placeholder="Email"
                        required
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        />
                      <LoadedFormButton type="submit" className="blue">{isLoading ? "Sending…" : "Send Now"}</LoadedFormButton>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SectionComponent>
  )
}

export default HubspotEmailCapture
