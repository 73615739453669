import { useStaticQuery, graphql } from "gatsby"
import { useEffect, useState } from "react";

export const usePricingData = () => {
  const { wordpress } = useStaticQuery(
    graphql`
      query FeatureQuery {
        wordpress {
          page(idType: URI, id: "pricing") {
            id
            Pricing {
              title
              sectionBgColour
              hubspotCode
              hubspotTitle
              faqs {
                question
                answer
              }
              priceTiers {
                tierButtonText
                tierLink
                tierTitle
                tierText
                tierPriceMonth
                tierColour
                tierPriceAnnual
                tierFeatures {
                  tierFeature
                }
              }
            }
          }
          features(first: 200) {
            nodes {
              title
              featuresPriceTiers {
								excludeFromPricePage
                feature {
                  feature
                  priceTier1
                  priceTier4
                  priceTier2
                  priceTier3
                }
              }
            }
          }
        }
      }
    `
  );

  return wordpress;
}
